<!--
 * @Author: inwen6
 * @Date: 2020-12-24 13:39:49
 * @LastEditTime: 2020-12-25 14:05:16
 * @LastEditors: your name
 * @Description: 提现
 * @FilePath: /heiqingting_gzh/src/views/withdraw_money.vue
-->
<template>
  <div class="withdraw_money">
    <div class="bg"></div>
    <div class="money_box">
      <div class="input_box">
        <span class="txt">￥</span>
        <input
          class="inp"
          type="number"
          v-model="money"
          :placeholder="placeholder"
          @blur="changemoney"
        />
      </div>
      <div class="tips" v-if="model1">
        <div>账户余额：{{ canWithdrawMoney }}</div>
        <div>手续费：{{ model1.serviceFee }}</div>
      </div>
      <div v-if="model1">
        <button class="btn" @click="withdrawClick" v-preventReClick v-if="model1.allWithdraw && model1.wechat">提现</button>
        <div v-else style="margin-top: 15px; line-height: 30px; color: red; font-size: 10px;">{{ !model1.allWithdraw?model1.allWithdrawTitle:model1.ali_wechat_title }}</div>
      </div>
      <div class="tips2">
        <div>1.默认将提现的金额提到注册微信的零钱账户中</div>
        <div>2.每次提现至少{{ model1?model1.lowestWithdrawAmount:'' }}块钱；</div>
      </div>
    </div>
    <div class="title_box">
      <div class="title_left">提现记录</div>
      <div class="right_box">
        <div @click="changeday">日期</div>
        <div class="jiao">
          <div
            class="bluesan"
            v-show="quarmas.orderBy != 'create_time' || quarmas.sort != 'desc'"
          ></div>
          <div
            class="bluesan1"
            v-show="quarmas.orderBy == 'create_time' && quarmas.sort == 'desc'"
          ></div>
          <div
            class="san"
            v-show="quarmas.orderBy != 'create_time' || quarmas.sort != 'asc'"
          ></div>
          <div
            class="san1"
            v-show="quarmas.orderBy == 'create_time' && quarmas.sort == 'asc'"
          ></div>
        </div>
        <div class="money" @click="changeqian">金额</div>
        <div class="jiao">
          <div
            class="bluesan"
            v-show="
              quarmas.orderBy != 'withdraw_amount' || quarmas.sort != 'desc'
            "
          ></div>
          <div
            class="bluesan1"
            v-show="
              quarmas.orderBy == 'withdraw_amount' && quarmas.sort == 'desc'
            "
          ></div>
          <div
            class="san"
            v-show="
              quarmas.orderBy != 'withdraw_amount' || quarmas.sort != 'asc'
            "
          ></div>
          <div
            class="san1"
            v-show="
              quarmas.orderBy == 'withdraw_amount' && quarmas.sort == 'asc'
            "
          ></div>
        </div>
      </div>
    </div>
    <!-- 列表 -->
    <div class="list_box">
      <div class="item" v-for="(item, index) in records" :key="index">
        <div class="item_left">
          <div class="num">提现单号：{{ item.withdrawSn }}</div>
          <div
              :class="{
            item_status1: true,
            type0: item.status == 0,
            type1: item.status == 1,
            type2: item.status == 2,
          }"
           v-if="item.dpStatus != 4  && item.dpStatus != 5">
            <font color="#666666">审批状态：</font>{{
                  item.status == 0
                  ? "待审批"
                  : item.status == 1
                  ? "审批通过"
                  : item.status == 2
                  ? "审批不通过"
                  : ""
            }}
          </div>
          <div class="i_money">¥{{ item.withdrawAmount }}</div>
          <div class="time" v-if="item.status == 1 && item.auditTime">
            {{
              item.auditTime.split("T")[0] +
              " " +
              item.auditTime.split("T")[1].split(".")[0]
            }}
          </div>
          <div  v-else-if="item.status == 0 && item.dpStatus == 0">
            <div class="time">延时模式：{{ item.dpModel }} </div>
            <div class="time">
              预计到账时间：{{
              item.dpTime.split("T")[0] +
              " " +
              item.dpTime.split("T")[1].split(".")[0] 
            }}
            </div>
           
          </div>
          <!-- <div class="time"  v-else-if="item.dpStatus == 4 || item.dpStatus == 5">
           {{ item.dpStatus == 4?'代理商待审核':'代理商已拒绝' }}
          </div> -->
          <div class="time" v-else>
            {{
              item.createTime.split("T")[0] +
              " " +
              item.createTime.split("T")[1].split(".")[0] 
            }}
          </div>
        </div>
        <div
          :class="{
            item_right: true,
            type0: item.withdrawStatus == 0,
            type1: item.withdrawStatus == 1,
            type2: item.withdrawStatus == 2,
          }"
        >
          {{
            item.withdrawStatus == 0
              ? "未转账"
              : item.withdrawStatus == 1
              ? "提现成功"
              : item.withdrawStatus == 2
              ? "提现失败"
              : ""
          }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Notify } from "vant";
import { Dialog } from 'vant';
export default {
  data() {
    return {
      model1:undefined,
      canWithdrawMoney: "",
      money: "",
      merchant: {},
      day: false,
      qian: false,
      quarmas: {
        pageNum: 1,
        pageSize: 10,
        sort: "desc",
        orderBy: "create_time",
      },
      records: [],
      // 预分成商户数据
      advance: null,
      // 提现金额提示
      placeholder: "请输入提现金额"
    }
  },
  created() {
    window.addEventListener('scroll', this.xxOnScroll)
  },
  mounted() {
    let merchant = localStorage.getItem("merchant");
    this.merchant = JSON.parse(merchant);
    console.log(this.merchant, "merchant");
    this.init();
    //审核记录
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.xxOnScroll, false);
  },
  methods: {
    init() {
      this.$http.searchMerchantRealId().then((res) => {
          if (res.code != 200) {
            Dialog.confirm({
              title: '提示',
              message:
                '请先填写实名信息',
            })
              .then(() => {
                // on confirm
                this.$router.push('./auth')
              })
              .catch(() => {
                // on cancel
              });        
          } 
      });
      this.$http.myWithdraw(this.quarmas).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.records = [...this.records, ...res.records];
        }
      });
      this.$http.withdrawMoney().then((res) => {
        console.log(res);
        this.model1 = res
      });
      this.$http.getInfo().then((res) => {
        this.canWithdrawMoney = res.balance;
        
      });
      this.getDetails();
    },

    xxOnScroll() {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight) {
        //写后台加载数据的函数
        this.quarmas.pageNum = this.quarmas.pageNum + 1;
        console.log(this.quarmas);
        this.init();
      }
    },
    changemoney() {
      console.log(this.money);
      if (this.money < this.model1.lowestWithdrawAmount) {
        this.money = "";
        Notify({ type: "primary", message: "每次提现金额不能低于"+this.model1.lowestWithdrawAmount+"元" });
        return;
      }
      if (this.money > this.canWithdrawMoney) {
        this.money = this.canWithdrawMoney;
        if (this.money < this.model1.lowestWithdrawAmount) {
          this.money = "";
          Notify({ type: "primary", message: "每次提现金额不能低于"+this.model1.lowestWithdrawAmount+"元" });
        }
      }
    },
    //提现
    withdrawClick() {
      if (this.money < this.model1.lowestWithdrawAmount) {
        this.money = "";
        Notify({ type: "primary", message: "每次提现金额不能低于"+this.model1.lowestWithdrawAmount+"元" });
        return;
      }
      if (this.merchant.weChat) {
        var isCan = localStorage.getItem("isCanWithdraw")
        if (this.money > 0) {
          if (isCan!=false && isCan!='false'){
            localStorage.setItem("isCanWithdraw",false)
            this.$http.merchanty({ withdrawAmount: this.money }).then((res) => {
              localStorage.setItem("isCanWithdraw",true)
              if(res.code == 205){
                this.records = [];
                this.init();
                return
              }
              alert(res.msg);
              this.records = [];
              this.init();  
            }).catch(err=>{
              localStorage.setItem("isCanWithdraw",true)
            });
          }else{
            Notify({ type: "primary", message: "请不要短时间内频繁操作" });
            //加个定时器 是为了防止还未返回就刷新了
            setTimeout(res1=>{
              localStorage.setItem("isCanWithdraw",true)
            },20000)
            
          }

        } else {
          Notify({ type: "primary", message: "金额不可大于可提现金额且最低"+this.model1.lowestWithdrawAmount+"元起" });
        }
      } else {
        Notify({ type: "primary", message: "请先前往首页绑定微信" });
      }
    },
    changeday() {
      this.records = [];
      this.quarmas.pageNum = 1;
      this.quarmas.orderBy = "create_time";
      if (this.quarmas.sort == "desc") {
        this.quarmas.sort = "asc";
      } else {
        this.quarmas.sort = "desc";
      }
      this.init();
    },
    changeqian() {
      this.records = [];
      this.quarmas.pageNum = 1;
      this.quarmas.orderBy = "withdraw_amount";
      if (this.quarmas.sort == "desc") {
        this.quarmas.sort = "asc";
      } else {
        this.quarmas.sort = "desc";
      }
      this.init();
    },
    /**
     * 获取当前登录商户数据
     */
    getDetails () {
      let that = this;
      this.$http.getDetails(null).then((res) => {
        if (res.code == 200) {
          if (res.advance != null && res.advance.isWithdrawal) {
            that.placeholder = "允许提现金额" + res.advance.withdrawalAmount;
          }

        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.withdraw_money {
  width: 100%;
  height: 100%;
  background: #ffffff;
  position: relative;
  margin: 0 auto;
  .bg {
    width: 100%;
    height: 180px;
    background: linear-gradient(180deg, #1890ff 0%, #35c3ff 100%);
  }
  .money_box {
    width: 335px;
    height: 211px;
    background: #ffffff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    z-index: 100;
    clear: both;
    margin: 0 auto;
    margin-top: -130px;
    box-sizing: border-box;
    padding: 40px;
    .input_box {
      width: 100%;
      height: 40px;
      // background: sandybrown;
      border-bottom: 1px solid #333333;
      display: flex;
      justify-content: start;
      align-items: center;
      .txt {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 28px;
      }
      .inp {
        width: 180px;
        height: 80%;
        border: none;
        outline: none;
        text-indent: 20px;
        font-size: 16px;
      }
      input::placeholder {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #cccccc;
      }
    }
    .tips {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: #333333;
      margin-top: 7px;
    }
    button{
      padding: 0px;
      box-sizing: border-box;
      margin: 0px;
    }
    .btn {
      display: block;
      width: 169px;
      height: 40px;
      background: linear-gradient(135deg, #35c3ff 0%, #1890ff 100%);
      border-radius: 20px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      line-height: 40px;
      margin: 0 auto;
      margin-top: 16px;
    }
    .tips2 {
      width: 100%;
      margin-top: 12px;
      div {
        width: 100%;
        font-size: 12px;
        color: #cccccc;
        margin-bottom: 5px;
      }
    }
  }
  .title_box {
    width: 375px;
    height: 50px;
    // background: sienna;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 20px;
    margin: 0 auto;
    .title_left {
      font-size: 16px;
      color: #333333;
    }
    .right_box {
      // width: 100px;
      height: 100%;
      // background: burlywood;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #333333;
      .money {
        margin-left: 16px;
      }
      .jiao {
        width: 12px;
        height: 100%;
        // background: red;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 4px;
        .bluesan {
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 4px solid #ccc;
        }
        .bluesan1 {
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 4px solid #1890ff;
        }
        .san {
          margin-top: 2px;
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid #ccc;
        }
        .san1 {
          margin-top: 2px;
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid #1890ff;
        }
      }
    }
  }
  // 列表
  .list_box {
    width: 100%;
    min-height: calc(100% - 311px);
    background: #f8f8f8;
    box-sizing: border-box;
    padding: 0 20px;
    overflow: hidden;
    .item {
      position: relative;
      width: 335px;
      background: #ffffff;
      border-radius: 8px;
      display: flex;
      box-sizing: border-box;
      padding: 16px;
      margin: 0 auto;
      margin-top: 8px;
      .item_left {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        .time {
          font-size: 12px;
          margin-top: 6px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
        .item_status1{
          margin-top: 6px;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
        }
        .i_money {
          font-size: 20px;
          margin-top: 6px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #1890ff;
        }
      }
      .item_right {
        position: absolute;
        top: 0;
        right: 0px;
        width: 100px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
      }
      
      .num {
        margin-top: 6px;
        color: #666666;
        font-size: 12px;
      }
      .type0 {
        color: #faad14;
      }
      .type1 {
        color: #1890ff;
      }
      .type2 {
        color: #f5222d;
      }
    }
  }
}
</style>
